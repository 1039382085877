.loader-page-home{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: 10000;
    background: #fff;
}
  
.loader-welcome{
    /* background: red; */
    width: 100%;
    padding: 0px 12px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.loader-welcome h3{
    margin-top: 12px;
    color: rgb(23, 23, 23);
    font-size: 90px;
    font-family: 'Roboto Slab', serif;
    width: 100%;
    text-align: center;
}
  
@media screen and (min-width:200px) and (max-width:1000px){
    .loader-welcome h3{
        font-size: 70px;
    }
}
  
@media screen and (min-width:200px) and (max-width:700px){
    .loader-welcome h3{
        font-size: 60px;
    }
}
  
@media screen and (min-width:200px) and (max-width:500px){
    .loader-welcome h3{
        font-size: 45px;
    }
}