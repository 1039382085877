.vehical-add-form-container{
    margin-top: 20px;
}

.vehicale-add-form-all-inputs{
    width: 100%;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,0.5fr));
}

.photo-upload-container {
    margin: 20px 0px;
}

.photo-upload-container-main{
    position: relative;
    width: 100%;
    border-radius: 12px;
    height: 370px;
    background: #FFFFFF;
    border: 2px dashed rgba(0 , 0 , 0 , 0.08);
    margin-top: 5px;
    cursor: pointer;
}

.visa-succ-phot-conatiner{
    height: 200px;
}

.photo-upload-container label{
    color: #111111;
    font-weight: 500;
    font-size: 17px;
    text-transform: capitalize;
    font-style: normal;
    cursor: pointer;
}

.photo-ippload-container{
    padding: 60px 0px;
    position: absolute;
    top: 50%;
    transform: translateY( -50%);
    width: 100%;
}

.photo-ippload-container p{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #757F95;
}

.photo-uploading-container{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #757F95;
    flex-direction: column;
    gap: 12px;
    padding: 25px 0px;
}

.photo-uploading-container p{
    font-weight: 500;
    font-size: 17px;
}

.admin-pkg-btn{
    width: 100%;
    margin-top: 20px;
    height: 50px;
    background-color: #222f4d;
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 8px;
}

@media screen and (min-width:200px) and (max-width:600px) {
    .vehicale-add-form-all-inputs{
        grid-template-columns: 1fr; /* Set child elements to take full width */
    }
    
}

.uploaded-image-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0px;
    gap: 12px;
    flex-wrap: wrap;
}

.each-image-uploaded {
    position: relative;
    width: 100px;
    height: 100px;
}

.each-image-uploaded img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px;
    z-index: 2;
}

.each-image-uploaded .svg-uploade-image{
    position: absolute;
    z-index: 10;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background: rgba(141, 141, 141, 0.896);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.each-image-uploaded .svg-uploade-image svg{
    color: #FFFFFF;
    font-weight: 800;
    font-size: 20px;
}



.vehicale-add-Btn{
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #B1252E;
    color: white;
    width: 150px;
    height: 50px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    gap: 8px;
    margin-top: 20px;
}

input {
    margin: 0 !important;
    padding: 8px 0px;
    background: #fff;
    border: 1px solid rgba(0,0,0,.08);
    border-radius: 12px;
    cursor: pointer;
    margin-top: 10px;
    position: relative;
    transition: all .2s linear;
  }

  .year-input {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding: 18px 15px;
  }

  .request-container{
    width: 100%;
    border-radius: 12px;
    height: fit-content;
    background: #FFFFFFFF;
    box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
    /* transform: translateY(-70px); */
}

.innner-container{
    padding: 25px;
}

.request-head{
    padding: 20px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.request-head h3{
    font-size: 25px;
    font-weight: 500;
    color: #111111;
}

.vehicle-feature-selector{
    width: 100%;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(150px,0.5fr));
    margin-top: 20px;
}

.custom-selector{
    position: relative;
    margin-top: 30px;
}

.custom-selector label{
    position: relative;
    color: #111111;
    font-weight: 500;
    font-size: 15px;
    font-size: bold;
    text-transform: capitalize;
    font-style: normal;
    cursor: pointer;
}

.custom-selector label::after{
    content: "";
    position: absolute;
    width: 25px;
    height: 2px;
    background: #fc0012;
    bottom: 8px;
    left: calc(100% + 5px);
    font-weight: bold;
}


.selected-option{
    position: relative;
    margin-top: 10px;
    padding: 8px 15px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s linear;
    background: #FFFFFF;
}

.year-input{
    position: relative;
    margin-top: 10px;
    padding: 8px 15px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s linear;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.year-input input{
    flex-basis: 85%;
    border: none;
    outline: none;
    background: none;
    color: #757F95;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}

.year-input input::placeholder{
    color: #757F95;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}

.Price-input input::-webkit-outer-spin-button , .Price-input input::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
}

.Price-input input[type="number"]{
    -moz-appearance: textfield;
}

.year-input svg{
    flex-direction: 13%;
    font-weight: 300;
    color: #757F95;

}



.clicked .year-input {
    border: 1px solid #B1252E; 
    transition: all 0.2s linear;
}

.clicked .selected-option{
    border: 1px solid #B1252E; 
    transition: all 0.2s linear;
}

.selected-option p{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #757F95;
}

.selected-option p svg{
    font-size: 25px;
    font-weight: 300;
    color: #757F95;
    transition: all .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
}

.options{
    position: absolute;
    z-index: 10;
    top: 110%;
    background: #FFFFFF;
    box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
    width: 100%;
    border-radius: 5px;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(-21px);
    transition: all .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
    pointer-events: none;
    opacity: 0;

}

.overflow-options{
    height: 250px;
    overflow-y: scroll;
}

.search-bar-model{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #B1252E;
    margin: 8px 0px;
}

.search-bar-model input{
    flex-basis: 95%;
    padding: 14px 12px;
    background: none;
    outline: none;
    border: none;
    color: #757F95;
    font-family: 'Roboto', sans-serif;
}

.search-bar-model svg{
    padding: 0px 5px;
    flex-basis: 15%;
    color: #757F95;
    font-size: 15px;
}

.search-bar-model input::placeholder{
    color: #757F95;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.option-opened{
    opacity: 1;
    pointer-events: unset;
    transform: scale(1) translateY(0);
    transition: all .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
}

.option-inner-container{
    padding: 10px;
}

.each-option{
    padding: 12px;
    border-radius: 8px;
    color: #757F95;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    transition: all 0.2s linear;
    text-transform: capitalize;
}

.options .selected{
    background: rgba(239,23,33,.05);
    color: #B1252E;
    font-weight: 600;
}


.each-option:hover{
    background: rgba(239,23,33,.05);
    color: #B1252E;
    transition: all 0.2s linear;
}

.option-inner-container:hover .selected{
    background: none;
}


.svg-clicked svg{
    transform: rotate(180deg);
    transition: all .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
}

.request-btn{
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.no-results-found {
    font-size: 15px;
    color: #B1252E;
    width: 100%;
    text-align: center;
    padding: 8px 0px;
    font-weight: 800;
}

@media screen and (min-width:200px) and (max-width:600px) {
    .vehicle-feature-selector{
        grid-template-columns: 1fr; /* Set child elements to take full width */
    }
    .innner-container{
        padding: 18px;
    }
    
}

@media screen and (min-width:200px) and (max-width:420px){
    .request-head h3{
        font-size: 20px;
    }
}

@media screen and (min-width:200px) and (max-width:340px){
    .request-head h3{
        font-size: 17px;
    }
}

@media screen and (min-width:200px) and (max-width:1250px){
    .vehicle-feature-selector{
        grid-template-columns: repeat(auto-fit,minmax(150px,0.5fr));
    }
}

@media screen and (min-width:200px) and (max-width:900px){
    .request-mail-full-container{
        flex-direction: column;
    }
}

@media screen and (min-width:200px) and (max-width:400px){
    .vehicle-feature-selector{
        grid-template-columns: 1fr;
    }
}



.each-option-color{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}

.color-each{
    width: 15px;
    height: 15px;
    border-radius: 50%;
}


.text-area-container {
    background: #fbfbfb;
    border-radius: 12px;
    cursor: pointer;
    height: 240px;
    margin-top: 15px;
    position: relative;
    width: 100%;
}  

.text-area-container textarea{
    border: none;
    outline: none;
    padding: 10px 15px;
    width: 100%;
    background: none;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}

.tourist-btn{
    margin-top: 20px;
    border: none;
    outline: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 8px;
}

.vehicale-add-form-all-inputs p{
    color: #B1252E;
    font-weight: 800;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 0px
}