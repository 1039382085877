.Popup {
    background-color: initial;
    bottom: 0;
    cursor: pointer;
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 0;
  }

  .Popup, .popup-open {
    opacity: 1;
    transition: all .3s linear;
      transition-delay: 0s;
    transition-delay: .4s;
  }

  .popup-open {
    background-color: #000c;
    pointer-events: inherit;
    z-index: 80;
  }