.main-admin-home{
    position: relative;
    left: 20%;
    width: 80%;
    transition: all 0.2s ease-in;
}

.main-admin-home-full{
    width: 100%;
    left: 0%;
    transition: all 0.2s ease-in;
}


.main-admin-innner{
    width: 100%;
    max-width: 95%;
    padding: 20px 0px;
    margin: auto;
}

.main-admin-innner h1{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 12px 0px;
    width: 100%;
    font-size: 22px;
    box-shadow:  inset 0 -1px #c3c9d0;
    margin: 0 !important;
}

@media screen and (min-width:200px) and (max-width:1300px){
    .main-admin-home{
        left: 0%;
        width: 100%;
    }
}