.login-page-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #FFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.login-page-image-container{
    flex-basis: 50%;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(320deg, rgba(0, 0, 0, 0.536), rgb(0, 0, 0.1)), url('../../../assets/img/login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.lpic-content{
    width: 100%;
    max-width: 90%;
    margin: 100px auto;
    color: #FFFFFF;
}

.lpic-content h3{
    font-size: 40px;
    margin: 6px 0px;
}

.lpic-content h5{
    font-size: 18px;
    margin: 0;
}

.login-page-form-container{
    flex-basis: 50%;
    width: 100%;
    height: 100%;
    position: relative;
    /* background: aqua; */
}

.loader-page{
    position: relative;
    height: 100vh;
  }
  
.loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}

.red-blur-login{
    width: 250px;
    height: 250px;
    background: rgba(252, 0, 18, 0.2);

}

.orange-blur-login{
    width: 250px;
    height: 250px;
    background: rgba(255, 151, 41, 0.2);
}


.lpfc-inner-container{
    /* background: red; */
    width: 100%;
    max-width: 90%;
    margin: auto;
    height: 100%;
    position: relative;
}

.nav-image{
    padding: 30px 0px;
}

.nav-image img{
    width: 300px;
    object-fit: contain;
}

.lpfc-inner-container form{
    width: 100%;
    height: fit-content;
    /* background: green; */
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50% , -70%);
}


.lpfc-inner-container form h3{
    font-size: 45px;
    font-weight: 400;
}

.all-form-feild{
    margin: 50px 0px;
}

.lpfc-form-feild{
    margin-bottom: 30px;
}

.lpfc-form-feild label{
    font-weight: 500;
    font-size: 18px;
}

.lpfc-form-feild .lpfc-input{
    background: #ededed;
    border-radius: 4px;
    width: 100%;
    margin-top: 12px ;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.lpfc-form-feild .lpfc-input input{
    padding: 18px 0;
    flex-basis: 87%;
    width: 100%;
    max-width: 95%;
    margin: auto;
    background: none;
    outline: none;
    border: none;    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #474f60;
}

.lpfc-form-feild .lpfc-input svg{
    flex-basis: 7%;
    color: #474f60;
    font-size: 20px;
    cursor: pointer;
}

.lpfc-form-feild .lpfc-input::placeholder{
    color: #474f60;
    line-height: 22px;
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

.signin-btn{
    padding: 20px 20px;
}

.signin-btn span{
    font-size: 20px;
    font-weight: 500;
}

.lpfc-inner-container form p{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    color: #474f60;
    gap: 8px;
}


.lpfc-inner-container form a{
    color: #EF1D26;
    text-decoration: none;
    font-weight: 500;
}

@media screen and (min-width:200px) and (max-width:1000px){
    .login-page-image-container{
        display: none;
    }
    .login-page-form-container{
        flex-basis: 100%;
        width: 100%;
        height: 100%;
    }
    .lpfc-inner-container form{
        top: 53%;
        transform: translate(-50% , -50%);
    }
    .all-form-feild{
        margin: 20px 0px;
    }
    
}

.theme-btn {
    background: #ef1d26;
    border: none;
    border-radius: 13px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    padding: 16px 20px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    transition: all .5s;
    z-index: 1;
  }

  .theme-btn::before {
    background: #111;
    border-radius: 50%;
    content: "";
    height: 500px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%) scale(0);
    transform: translateY(-50%) translateX(-50%) scale(0);
    transition: .5s cubic-bezier(.25,.46,.45,.94);
    width: 500px;
    z-index: -1;
  }

  .red-blur{
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 550px;
    height: 550px;
    background: rgba(252, 0, 18, 0.2);
    -webkit-filter: blur(200px);
    filter: blur(200px);
    border-radius: 50%;

}

.orange-blur{
    position: absolute;
    display: block;
    bottom: 0px;
    right: 0px;
    width: 550px;
    height: 550px;
    background: rgba(255, 151, 41, 0.2);
    -webkit-filter: blur(200px);
    filter: blur(200px);
    border-radius: 50%;
}

  .orange-blur, .red-blur {
    border-radius: 50%;
    display: block;
    -webkit-filter: blur(200px);
    filter: blur(200px);
    height: 550px;
    position: absolute;
    width: 550px;
  }

  .red-blur-login{
    width: 250px;
    height: 250px;
    background: rgba(252, 0, 18, 0.2);

    }

    .orange-blur-login{
        width: 250px;
        height: 250px;
        background: rgba(255, 151, 41, 0.2);
    }

  form{
    color: #000;
  }

 